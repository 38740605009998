import React from "react";
import logo from "../images/LOGO.png";
import UC from "../images/under_constr.gif";

function Homepage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        marginTop: "130px",
      }}
    >
      <img src={logo} alt="Logo" style={{ width: "10%", height: "auto" }} />
      <p
        style={{
          letterSpacing: "0.3em",
          fontWeight: "bold",
          fontFamily: "Ubuntu",
          marginBottom: 0,
        }}
      >
        STATHIS CHLIAOUTAKIS
      </p>
      <p
        style={{
          fontFamily: "Ubuntu",
          letterSpacing: "0.1em",
          margin: 0,
          marginBottom: "10px",
        }}
      >
        ARCHITECTURE STUDIO
      </p>
      <p
        style={{
          fontSize: "12px",
          fontFamily: "Ubuntu",
          margin: 0,
          color: "gray",
        }}
      >
        Αρχιτέκτων Μηχανικός ΕΜΠ
      </p>
      <p
        style={{
          fontSize: "12px",
          fontFamily: "Ubuntu",
          margin: 0,
          color: "gray",
        }}
      >
        +30 6992156742
      </p>
      <p
        style={{
          fontSize: "12px",
          fontFamily: "Ubuntu",
          margin: 0,
          color: "gray",
        }}
      >
        s.chliaoutakis@hotmail.com
      </p>

      <img
        src={UC}
        alt="UC"
        style={{ width: "10%", height: "auto", marginTop: "50px" }}
      />
    </div>
  );
}

export default Homepage;
